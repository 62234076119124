import request from '@/utils/request'

export function getDetailRes(data){
    return request({
      method: 'POST',
      url:`/detail/getDetailResPC` ,
      data
    })
  }

export function setPageView(data){
    return request({
        method: 'POST',
        url: `/detail/setPageView`,
        data
    })
  }
