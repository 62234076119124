<template>
  <div class="videoPlay-container">
    <div class="bgc">
      <div class="title">
        <div class="heartitle">
          <img
            class="companylogin"
            src="../../assets/列表页切图/2-logo@2x.png"
            alt=""
            @click="$router.push('/')"
          />
          <img
            class="positioning"
            @click="$router.push('/map')"
            src="../../assets/列表页切图/dingwei.png"
            alt=""
          />
          <img
            class="map"
            @click="$router.push('/map')"
            src="../../assets/列表页切图/lishiditu.png"
            alt=""
          />
          <div class="titleright">
            <el-input
              class="input"
              placeholder="搜索"
              color="#F3AA19;"
              v-model="queryInfo.resName"
              @keyup.enter.native="onSearch"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <!--            <div class="mylogin">-->
            <!--              <img class="myimg" src="../../assets/首页切图/my.png" alt="" />-->
            <!--              <span class="logintext">请登录</span>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="hearlist">
          <!-- 历史人物 -->
          <div
            class="boxlist"
            @click="
              clicklist('260125268738580480', '260125269116338176', '历史人物')
            "
          >
            <img
              v-if="this.queryInfo.functionId == '260125268738580480'"
              src="../../assets/播放页切图/lishirenwu-1@3x.png"
              alt=""
            />
            <img
              v-else
              src="../../assets/播放页切图/lishirenwu@3x.png"
              alt=""
            />
          </div>
          <!-- 典故故事 -->
          <div
            class="boxlist"
            @click="
              clicklist('260125237222580224', '260125237294153728', '典故故事')
            "
          >
            <img
              v-if="this.queryInfo.functionId == '260125237222580224'"
              src="../../assets/播放页切图/diangugushi-1@3x.png"
              alt=""
            />
            <img
              v-else
              src="../../assets/播放页切图/diangugushi@3x.png"
              alt=""
            />
          </div>
          <!-- 革命英雄 -->
          <div
            class="boxlist"
            @click="
              clicklist('260125264988872704', '260125265039474688', '革命英雄')
            "
          >
            <img
              v-if="this.queryInfo.functionId == '260125264988872704'"
              src="../../assets/播放页切图/gemingyingxiong-1@3x.png"
              alt=""
            />
            <img
              v-else
              src="../../assets/播放页切图/gemingyingxiong@3x.png"
              alt=""
            />
          </div>
          <!-- 经典故事 -->
          <div
            class="boxlist"
            @click="
              clicklist('260125225902153728', '260125225977921536', '经典故事')
            "
          >
            <img
              v-if="this.queryInfo.functionId == '260125225902153728'"
              src="../../assets/播放页切图/jingdiangushi-1@3x.png"
              alt=""
            />
            <img
              v-else
              src="../../assets/播放页切图/jingdiangushi@3x.png"
              alt=""
            />
          </div>
          <!-- 历史故事 -->
          <div
            class="boxlist"
            @click="
              clicklist('260125246395523072', '260125246450319360', '历史故事')
            "
          >
            <img
              v-if="this.queryInfo.functionId == '260125246395523072'"
              src="../../assets/播放页切图/lishigushi-1@3x.png"
              alt=""
            />
            <img
              v-else
              src="../../assets/播放页切图/lishigushi@3x.png"
              alt=""
            />
          </div>
          <!-- 一带一路 -->
          <div
            class="boxlist"
            @click="
              clicklist('260125270852509696', '260125270890528768', '一带一路')
            "
          >
            <img
              v-if="this.queryInfo.functionId == '260125270852509696'"
              src="../../assets/播放页切图/yidaiyilu-1@3x.png"
              alt=""
            />
            <img v-else src="../../assets/播放页切图/yidaiyilu@3x.png" alt="" />
          </div>
          <!-- 榜样人物 -->
          <div
            class="boxlist"
            @click="
              clicklist('260125261838950400', '260125252423008256', '榜样人物')
            "
          >
            <img
              v-if="this.queryInfo.functionId == '260125261838950400'"
              src="../../assets/播放页切图/bangyangrenwu-1@3x.png"
              alt=""
            />
            <img
              v-else
              src="../../assets/播放页切图/bangyangrenwu@3x.png"
              alt=""
            />
          </div>
          <!-- 智慧故事 -->
          <div
            class="boxlist"
            @click="
              clicklist('260125252359823360', '260125261876969472', '智慧故事')
            "
          >
            <img
              v-if="this.queryInfo.functionId == '260125252359823360'"
              src="../../assets/播放页切图/zhihuigushi-1@3x.png"
              alt=""
            />
            <img
              v-else
              src="../../assets/播放页切图/zhihuigushi@3x.png"
              alt=""
            />
          </div>
        </div>
        <div class="videoplay">
          <div class="left">
            <img
              class="plays"
              src="../../assets/播放页切图/bofangbeijing@3x.png"
              alt=""
            />
            <div class="test_two_box">
              <video id="myVideo" class="video-js">
                <!--                <source type="video/mp4" />-->
              </video>
            </div>
            <div class="video-bottom">
              <div class="browse">
                <img src="../../assets/播放页切图/liulan@2x.png" alt="" />
                <h3>{{ this.resInfo.pageView }}</h3>
              </div>
              <div class="thumb">
                <img src="../../assets/播放页切图/aixin@2x.png" alt="" />
                <h3>{{ this.resInfo.collect }}</h3>
              </div>
            </div>
          </div>
          <div class="right">
            <img
              class="people"
              src="../../assets/播放页切图/renwu@3x.png"
              alt=""
            />
            <div class="toplist">
              <div class="line"></div>
              <span class="texts">目录</span>
            </div>
            <!-- <div class="videolist">
              <div class="rows"></div>
              <div class="rows"></div>
            </div> -->
            <ul
              class="videolist"
              v-infinite-scroll="onload"
              style="overflow: auto; list-style: none"
            >
              <div class="top1">
                <h6 class="playtext">当前播放</h6>
                <div class="rows" v-if="resList[0]">
                  <div class="boxleft">
                    <el-image
                      class="listImg"
                      :src="url + resList[0].imgUrl"
                      lazy
                    ></el-image>
                    <!-- <img class="listImg" :src="url+item.imgUrl"> -->
                    <div class="bottoms">
                      <img src="../../assets/播放页切图/aixin@2x.png" alt="" />
                      <h6>{{ resList[0].collect }}</h6>
                    </div>
                  </div>
                  <div class="boxright">
                    <h5 class="h5-1">
                      {{ resList[0].resName }}
                    </h5>
                    <span>{{ resList[0].pageView }}次浏览</span>
                  </div>
                </div>
              </div>
              <div>
                <h6 class="playtext">播放列表</h6>
                <!-- <h6 class="playtexts">播放列表</h6> -->
                <li
                  v-for="(item, index) in resList.slice(1)"
                  :key="index"
                  class="rows"
                  @click="playInfo(item)"
                >
                  <div class="boxleft">
                    <el-image
                      class="listImg"
                      :src="url + item.imgUrl"
                      lazy
                    ></el-image>
                    <!-- <img class="listImg" :src="url+item.imgUrl"> -->
                    <div class="bottoms">
                      <img src="../../assets/播放页切图/aixin@2x.png" alt="" />
                      <h6>{{ item.collect }}</h6>
                    </div>
                  </div>
                  <div class="boxright">
                    <h5 v-if="item.id == playId" class="h5-1">
                      {{ item.resName }}
                    </h5>
                    <h5 v-else>{{ item.resName }}</h5>
                    <span>{{ item.pageView }}次浏览</span>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="center-bom">
        <img
          class="botmlogin"
          src="../../assets/列表页切图/logo@2x.png"
          alt=""
        />
        <div class="bomtext">
          <h6>杭州晨星创文网络科技有限公司</h6>
          <span class="spanone"
            >公司地址：浙江省杭州市钱塘新区佳宝科创中心2幢508室</span
          ><br />
          <span class="spantwo">联系电话：0571-61075056</span>
        </div>
        <img class="opcode" src="../../assets/record.jpg" alt="" />
        <div class="boms">Icp备18038160号-3</div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Common from "@/components/comm";
import { getRecommend, addAccessStatistics } from "@/api/home";
import { getDetailRes, setPageView } from "@/api/videoPlay";
import qs from "qs";
import { queryRes } from "@/api/home";

export default {
  name: "videoPlay",
  components: {},
  props: {},
  data() {
    return {
      url: Common.getUrl(),
      value: "",
      resList: [],
      videoInfo: {
        collect: "",
        pageView: "",
      },
      playId: "",
      queryInfo: {
        functionId: "", //分类id
        resId: "", //资源id
        pageNo: 1, //页数
        pageSize: 10, //页容量
        resName: "",
      },
      videoUrl: "",
      hotlist: [],
      resInfo: {},
      page: true,
      title: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.queryInfo.resId = this.$route.query.resId;
    this.queryInfo.functionId = this.$route.query.functionId;
    this.queryInfo.collect = this.$route.query.collect;
    this.queryInfo.pageView = this.$route.query.pageView;
    this.title = this.$route.query.title;
    getDetailRes(qs.stringify(this.queryInfo)).then((res) => {
      if (res.data.code == 200) {
        this.resInfo = res.data.data.resources;
        this.playInfo(this.resInfo);
      } else {
        this.resInfo = res.data.data;
        this.playInfo(this.resInfo);
      }
    });
  },
  mounted() {
    this.initVideo();
  },
  methods: {
    async addAccessStatistics(cont) {
      let data = {
        terminal: 1,
        content: cont,
      };
      const { data: res } = await addAccessStatistics(qs.stringify(data));
    },
    getResList() {
      if (!this.page) {
        return;
      }
      getDetailRes(qs.stringify(this.queryInfo)).then((res) => {
        if (res.data.code == 200) {
          var items = res.data.data.resList.records;

          if (this.queryInfo.pageNo == 1) {
            this.resList.push(this.resInfo);
          }

          for (var i = 0; i < items.length; i++) {
            this.resList.push(items[i]);
          }
          if (items.length < this.queryInfo.pageSize - 1) {
            this.page = false;
          }
          this.queryInfo.pageNo += 1;
        } else {
          this.resList.push(this.resInfo);
          this.page = false;
          this.$message("该模块暂未开通");
        }
      });
    },
    onSearch(val) {
      let con = "搜索:" + this.queryInfo.resName;
      this.addAccessStatistics(con);
      queryRes(qs.stringify(this.queryInfo)).then((res) => {
        if (res.data.code === 200) {
          this.resList = res.data.data.records;
        }
      });
    },
    onload() {
      this.getResList();
    },
    clicklist(item, item2, title) {
      this.queryInfo.functionId = item;
      this.resList = [];
      this.queryInfo.pageNo = 1;
      this.page = true;
      this.onload();
      this.addAccessStatistics(title);
    },
    initVideo() {
      //初始化视频方法
      let myPlayer = this.$video(myVideo, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //自动播放属性,muted:静音播放
        autoplay: true,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        //设置视频播放器的显示宽度（以像素为单位）
        width: "800px",
        //设置视频播放器的显示高度（以像素为单位）
        height: "400px",
        poster: this.url + this.resInfo.imgUrl,
      });
    },
    playInfo(item) {
      this.resInfo = item;
      this.playId = item.id;
      var resUrl = this.url + item.resUrl; //点击视频地址
      var poster = this.url + item.imgUrl;
      var player = videojs("myVideo");
      var data = { resId: item.id };
      setPageView(qs.stringify(data)).then((res) => {});
      player.src({
        src: resUrl,
        poster: poster,
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //自动播放属性,muted:静音播放
        autoplay: true,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        //设置视频播放器的显示宽度（以像素为单位）
        width: "800px",
        //设置视频播放器的显示高度（以像素为单位）
        height: "400px",
      });
      this.addAccessStatistics(item.resName);
    },
  },
};
</script>

<style scoped lang="less">
.bgc {
  width: 100vw;
  // height: 100vh;
  // width: 1920px;
  height: 1080px;
  // width: 100%;
  // height: 100%;
  background: url("../../assets/列表页切图/bgc.png");
  background-size: 100% 100%;
}
.title {
  position: relative;
  width: 1148px;
  height: 850px;
  margin: 0 auto;
}
.companylogin {
  width: 160px;
  height: 60px;
  margin-top: -20px;
}
.heartitle {
  position: relative;
  width: 100%;
  padding-top: 20px;
  .positioning {
    position: absolute;
    top: 45%;
    left: 25.5%;
    animation: myfirst 2s infinite;
    width: 20px;
    height: 29px;
  }
  @keyframes myfirst {
    0% {
      transform: translate(0px, 0px);
    }
    50% {
      transform: translate(0px, -12px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
}
.map {
  width: 112px;
  height: 79px;
  margin-left: 7%;
  margin-top: 1%;
}
.titleright {
  position: absolute;
  top: 25px;
  right: 10px;
  width: 270px;
  height: 84px;
}
/deep/input::-webkit-input-placeholder {
  color: #f3aa19;
  font-weight: 400;
  font-size: 16px;
}
/deep/input::-moz-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
/deep/input::-ms-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
.input {
  width: 180px;
  height: 46px;
  opacity: 0.52;
  /deep/.el-icon-search:before {
    padding-left: 30px;
    color: #f3aa19;
    font-size: 18px;
    line-height: 46px;
  }
  /deep/.el-input__inner {
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    color: #f3aa19;
    line-height: 47px;
    padding-left: 58px;
  }
}
.mylogin {
  position: absolute;
  top: 0;
  right: 2px;
  width: 48px;
  height: 85px;
  .myimg {
    width: 46px;
    height: 46px;
  }
  .logintext {
    width: 48px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
  }
}
.hearlist {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;

  .boxlist img {
    position: relative;
    margin-right: 10px;
    margin-top: -10px;
    width: 138px;
    // background: url('../../assets/播放页切图/lishigushi@3x.png');
  }
  //  h4 {
  //     position: absolute;
  //     top: 0px;
  //     right: 10px;
  //     line-height: 65px;
  //     width: 64px;
  //     font-size: 16px;
  //     font-family: Microsoft YaHei;
  //     font-weight: bold;
  //     color: #d39e09;
  //   }
}
.plays {
  width: 848px;
  height: 631px;
  background-size: 100% 100%;
}
.right {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 270px;
  height: 548px;
  background: #fff;
  .people {
    width: 310px;
    height: 100px;
    position: absolute;
    top: -81px;
    left: -28px;
  }
  .toplist {
    height: 55px;
    .line {
      width: 6px;
      height: 28px;
      background: #f3a132;
      border-radius: 3px;
      position: absolute;
      top: 21px;
      left: 10%;
    }
    .texts {
      position: absolute;
      left: 17%;
      top: 19px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f3a132;
    }
  }
  .videolist {
    width: 100%;
    height: 488px;
    padding-left: 20px;
    flex-wrap: wrap;
    .top1 {
      width: 210px;
      height: 150px;
    }
    .playtext {
      font-size: 16px;
      font-family: Microsoft YaHei;
      margin-left: 20px;
    }
    .playtexts {
      position: absolute;
      top: 210px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      margin-left: 20px;
    }
    .rows {
      position: relative;
      width: 210px;
      height: 110px;
      margin-bottom: 17px;
      margin-top: 10px;
      .boxleft {
        position: absolute;
        left: 2px;
        top: 0;
        width: 110px;
        height: 110px;
        border-radius: 11px;

        .bottoms {
          position: absolute;
          bottom: 0;
          height: 25px;
          width: 100%;
          img {
            position: absolute;
            top: 3%;
            left: 12%;
            width: 16px;
            height: 14px;
          }
          h6 {
            position: absolute;
            bottom: 0;
            left: 40%;
            width: 40px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .boxright {
        position: absolute;
        top: 0;
        right: -10px;
        width: 100px;
        height: 100%;
        h5 {
          position: absolute;
          top: 20px;
          left: 0px;
          width: 100px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 32px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .h5-1 {
          color: #f3a132;
        }
        span {
          position: absolute;
          top: 66px;
          left: 0;
          height: 14px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c9c9c9;
        }
      }
    }
    // .rowes {
    //   position: relative;
    //   width: 100%;
    //   height: 110px;
    //   margin-bottom: 17px;
    //   .boxleft {
    //     position: absolute;
    //     left: 2px;
    //     top: 0;
    //     width: 110px;
    //     height: 110px;
    //     border-radius: 11px;
    //     margin-top: 30px;
    //     .bottoms {
    //       position: absolute;
    //       bottom: 0;
    //       height: 25px;
    //       width: 100%;
    //       img {
    //         position: absolute;
    //         top: 3%;
    //         left: 12%;
    //         width: 16px;
    //         height: 14px;
    //       }
    //       h6 {
    //         position: absolute;
    //         bottom: 0;
    //         left: 40%;
    //         width: 40px;
    //         font-size: 14px;
    //         font-family: Microsoft YaHei;
    //         font-weight: 400;
    //         color: #ffffff;
    //       }
    //     }
    //   }
    //   .boxright {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     width: 120px;
    //     height: 120px;
    //     margin-top: 30px;
    //     h5 {
    //       position: absolute;
    //       top: 20px;
    //       left: 0px;
    //       width: 112px;
    //       font-size: 16px;
    //       font-family: Microsoft YaHei;
    //       font-weight: 400;
    //       line-height: 32px;
    //       white-space: nowrap;
    //       overflow: hidden;
    //       text-overflow: ellipsis;
    //     }
    //     .h5-1 {
    //       color: #f3a132;
    //     }
    //     span {
    //       position: absolute;
    //       top: 66px;
    //       left: 0;
    //       height: 14px;
    //       font-size: 14px;
    //       font-family: Microsoft YaHei;
    //       font-weight: 400;
    //       color: #c9c9c9;
    //     }
    //   }
    // }
  }
}
.test_two_box {
  position: absolute;
  top: 20%;
  left: 20px;
}
.videoplay {
  position: relative;
}
/deep/.video-js {
  background-color: none;
  width: 754px;
  height: 424px;
  margin-left: 40px;
  font-size: 15px;
}
.video-bottom {
  position: absolute;
  bottom: 20px;
  left: 60px;
  width: 210px;
  height: 50px;
  .browse {
    float: left;
    position: relative;
    width: 90px;
    height: 50px;
    img {
      position: absolute;
      top: 22.5%;
      left: 0;
      width: 32px;
      height: 20px;
    }
    h3 {
      position: absolute;
      top: -10.5%;
      right: 0;
      height: 14px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c9c9c9;
    }
  }
  .thumb {
    position: relative;
    float: right;
    width: 87px;
    height: 50px;
    img {
      position: absolute;
      left: 0;
      top: 22.5%;
      width: 24px;
      height: 21px;
    }
    h3 {
      position: absolute;
      right: 0;
      top: -10.5%;
      height: 14px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c9c9c9;
    }
  }
}
.listImg {
  width: 110px;
  height: 110px;
  border-radius: 11px;
}
.bottom {
  width: 100vw;
  height: 298px;
  background: #353535;
  .center-bom {
    position: relative;
    width: 1148px;
    height: 100%;
    margin: 0 auto;
    .botmlogin {
      position: absolute;
      top: 40px;
      left: 0;
      width: 156px;
      height: 147px;
    }
    .bomtext {
      position: absolute;
      top: 70px;
      left: 195px;
      width: 534px;
      height: 110px;
      h6 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #868686;
      }
      .spanone {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
      }
      .spantwo {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
      }
    }
    .opcode {
      position: absolute;
      right: 0;
      top: 50px;
      width: 140px;
      height: 140px;
      background-color: #fff;
    }
    .boms {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #868686;
    }
  }
}
</style>